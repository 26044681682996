import {
    MutableRefObject,
    useEffect,
} from 'react';

const CLASS_NAME_TO_DISABLE = 'h-overflow-hidden';

function getElementLock(targetElement: HTMLElement): number {
    const readLock = Number(targetElement.dataset.useScrollDisableLock);

    // Если удалось успешно прочитать число из элемента
    if (typeof readLock === 'number' && Number.isFinite(readLock)) {
        return readLock;
    }

    return 0;
}

function setElementLock(targetElement: HTMLElement, newLockValue: number) {
    // Записываем значение числа использований в элемент, чтобы не было конфликтов с другими использованиями хука
    targetElement.dataset.useScrollDisableLock = newLockValue.toString();
}

function disableElement(targetElement: HTMLElement) {
    // Читаем и обновляем число блокировок элемента
    const lock = getElementLock(targetElement);
    setElementLock(targetElement, lock + 1);

    // Если элемент раньше не блокировали, то добавляем класс блокировки
    if (lock <= 0) {
        targetElement.classList.add(CLASS_NAME_TO_DISABLE);
    }
}

function enableElement(targetElement: HTMLElement) {
    // Читаем и обновляем число блокировок элемента
    const lock = getElementLock(targetElement);
    const nextLock = lock - 1;

    setElementLock(targetElement, nextLock);

    // Если элемент больше не используется, удаляем класс блокировки
    if (nextLock <= 0) {
        targetElement.classList.remove(CLASS_NAME_TO_DISABLE);
    }
}

/**
 * Хук для отключения скролла всей страницы (или конкретного элемента, если указан).
 *
 * Хук одновременно может безопасно применяться в разных компонентах и нескольких местах одновременно.
 * Полезно при создании модальных окон или элементов, которые должны отображаться на отдельном слое поверх основного экрана.
 *
 * @param disable Флаг, указывающий, что необходимо заблокировать скролл элемента.
 * @param targetElement Элемент, который необходимо заблокировать или ссылка на него. Если не указано, то для блокировки используется весь документ.
 *
 * @category Hooks
 *
 * @example
 * export default function Modal({ isOpen }) {
 *      useScrollDisable(isOpen);
 *      // ...
 * }
 */
export default function useScrollDisable(disable: boolean, targetElement?: HTMLElement|MutableRefObject<HTMLElement>|undefined) {
    useEffect(() => {
        const defaultTargetElement = targetElement === undefined ? document.body : targetElement;

        // Если элемент не указан, то ничего не делаем
        if (!defaultTargetElement) {
            return;
        }

        // Получаем элемент для блокировки из параметра или вычисляем по ссылке
        const element = defaultTargetElement instanceof HTMLElement
            ? defaultTargetElement
            : defaultTargetElement.current;

        // Если ссылка на элемент потерялась, то ничего не делаем
        if (!element) {
            return;
        }

        if (disable) {
            disableElement(element);

            return () => {
                enableElement(element);
            };
        }
    }, [ targetElement, disable ]);
}
